import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUs = () => {
	const [success, setSuccess] = useState(null);
	const [error, setError] = useState(null);
	const [isLoading, setLoading] = useState(false);
	const captchaRef = useRef(null);

	function validate(values) {
		const errors = {};

		if (!values.uname) {
			errors.uname = "Please add your name";
		}

		if (!values.industry) {
			errors.industry = "Please add industry/company name";
		}

		if (!values.email) {
			errors.email = "Please add E-mail";
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
			errors.email = "Invalid email address";
		}

		if (typeof values.phone !== "undefined") {
			if (!values.phone.match(/^[0-9]{10}$/)) {
				errors.phone = "Invalid phone number";
			}
		}

		if (!values.message) {
			errors.message = "Please add message";
		}

		if (!values.recaptcha) {
			errors.recaptcha = "Captcha require";
		}

		return errors;
	}

	const onSubmit = async (values, actions) => {
		setLoading(true);
		await axios({
			method: "POST",
			// url: "https://wwwitsolutions.com/demo/one/v9/services/emailservice.php",
			url: "https://qualitechmetal.com/services/emailservice.php",
			data: values,
			headers: { "Content-Type": "multipart/form-data" },
		})
			.then(function (res) {
				if (res && res.data.flag === "success") {
					setError(null);
					setSuccess(res.data.flag);
				} else if (res && res.data.flag === "error") {
					setError(res.data.msg);
					setSuccess(null);
				}
				actions.resetForm();
				captchaRef.current.reset();
				setLoading(false);
			})
			.catch((err) => {
				if (err && err.response) setError(err.response.data.message);
				setSuccess(null);
			});
	};
	const { handleSubmit, handleChange, touched, values, errors, setFieldValue } = useFormik({
		initialValues: {
			uname: "",
			email: "",
			industry: "",
			phone: "",
			message: "",
			recaptcha: "",
		},
		validate,
		onSubmit,
	});

	return (
		<>
			<div className='col-md-6'>
				{!error && success && <div className='alert alert-success'>{success ? "Thank you! Your contact request has submitted successfully, we will get back to you soon." : ""}</div>}
				{!success && error && <div className='alert alert-danger'>{error ? "Please try again!!" : ""}</div>}
				<form className='row form-contact' onSubmit={handleSubmit}>
					<div className='col-lg-6'>
						<div className='quote-item'>
							<input type='text' name='uname' placeholder='Name' onChange={handleChange} value={values.uname} className={touched.uname && errors.uname ? "invalid_input" : ""} />
							{touched.uname && errors.uname ? <div className='error_msg'>{errors.uname}</div> : null}
						</div>
					</div>
					<div className='col-lg-6'>
						<div className='quote-item'>
							<input type='email' name='email' placeholder='Email' onChange={handleChange} value={values.email} className={touched.email && errors.email ? "invalid_input" : ""} />
							{touched.email && errors.email ? <div className='error_msg'>{errors.email}</div> : null}
						</div>
					</div>
					<div className='col-lg-6'>
						<div className='quote-item'>
							<input type='text' name='industry' placeholder='Industry/Company' onChange={handleChange} value={values.industry} className={touched.industry && errors.industry ? "invalid_input" : ""} />
							{touched.industry && errors.industry ? <div className='error_msg'>{errors.industry}</div> : null}
						</div>
					</div>
					<div className='col-lg-6'>
						<div className='quote-item'>
							<input type='tel' name='phone' placeholder='Phone' onChange={handleChange} value={values.phone} className={touched.phone && errors.phone ? "invalid_input" : ""} />
							{touched.phone && errors.phone ? <div className='error_msg'>{errors.phone}</div> : null}
						</div>
					</div>
					<div className='col-lg-12'>
						<div className='quote-item'>
							<div className='quote-item'>
								<textarea name='message' placeholder='Message Details!' onChange={handleChange} value={values.message} className={touched.message && errors.message ? "invalid_input" : ""}></textarea>
								{touched.message && errors.message ? <div className='error_msg'>{errors.message}</div> : null}
							</div>
						</div>
					</div>
					<div className='col-lg-12'>
						<div className='quote-item'>
							<div className='quote-item'>
								<ReCAPTCHA sitekey='6Lc_ACcnAAAAANOru1y40UtlpR4-0EUA_j9p2iH6' ref={captchaRef} render='explicit' theme='dark' onChange={(e) => setFieldValue("recaptcha", captchaRef.current.getValue())} />
								{touched.recaptcha && errors.recaptcha ? <div className='error_msg'>{errors.recaptcha}</div> : null}
							</div>
						</div>
					</div>
					<div className='col-lg-12'>
						<div className='quote-item'>
							{isLoading ? (
								<button class='btn-1 btn-3 submit' type='button' disabled>
									<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>
									Submit Request
								</button>
							) : (
								<button className='btn-1 btn-3 submit' type='submit'>
									<span>Submit Request</span>
								</button>
							)}
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default ContactUs;
