export const MenuBox = () => {
    return (
        <div className="menu-box">
        <div className="inner-menu">
          <div className="website-info">
            <a href="index.html" className="logo">
              <img
                className="img-fluid"
                src="assets/images/logo/01_logo-1.png"
                alt="02 Logo"
              />
            </a>
            <p>
              Qualitech Are A Industry &amp; Manufacturing Services Provider
              Institutions. Suitable For Factory, Manufacturing, Industry,
              Engineering, Construction And Any Related Industry Care Field.
            </p>
          </div>
          <div className="contact-info">
            <h4>Contact Info</h4>
            <div className="contact-box">
              <i className="ar-icons-call"></i>
              <div className="box">
                <a className="phone" href="tel:01212843661">
                  0121284 3661
                </a>
                <a className="phone" href="tel:01029134630">
                  0102913 4630
                </a>
              </div>
            </div>
            <div className="contact-box">
              <i className="ar-icons-email"></i>
              <div className="box">
                <a className="mail" href="mailto:support@Qualitech.com">
                  Support@Qualitech.com
                </a>
                <a className="mail" href="mailto:mailbox@ar-coder.com">
                  MailBox@AR-Coder.com
                </a>
              </div>
            </div>
            <div className="contact-box">
              <i className="ar-icons-location"></i>
              <div className="box">
                <p>14D Street Brooklyn,</p>
                <p>New York.</p>
              </div>
            </div>
          </div>
          <div className="follow-us">
            <h4>Follow Us</h4>
            <ul className="icon-follow">
              <li>
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
            </ul>
          </div>
          <span className="menu-box-icon exit">
            <i className="fas fa-times"></i>
          </span>
        </div>
      </div>

    )
}