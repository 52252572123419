const clientsData = [
	{
		id: "c1",
		name: "L&T Construction",
		logo: "1 L_T Construction.jpg",
	},
	{
		id: "c2",
		name: "Kirloskar Brothers Limited",
		logo: "2 Kirloskar Brothers Limited.jpg",
	},
	{
		id: "c3",
		name: "Nagarjuna Construction Company",
		logo: "3 Nagarjuna Construction Company.jpg",
	},
	{
		id: "c4",
		name: "MEIL (Megha Engineering and Industries Limited)",
		logo: "4 MEIL (Megha Engineering and Industries Limited).jpg",
	},
	{
		id: "c5",
		name: "PHED RGLC",
		logo: "5 PHED RGLC.jpg",
	},
	{
		id: "c6",
		name: "JMC Kalpataru",
		logo: "6 - JMC Kalpataru.jpg",
	},
	{
		id: "c7",
		name: "GVPR Engineers Limited",
		logo: "7 - GVPR Engineers Limited.jpg",
	},
	{
		id: "c8",
		name: "Va Tech Wabag",
		logo: "8 - Va Tech Wabag.jpg",
	},
	{
		id: "c9",
		name: "Ultratech",
		logo: "10 - Ultratech.jpg",
	},
	{
		id: "c10",
		name: "Offshore Infra Ltd",
		logo: "11 - Offshore Infra Ltd..png",
	},
	{
		id: "c11",
		name: "Gammon",
		logo: "12 - Gammon.png",
	},
	{
		id: "c12",
		name: "IVRCL",
		logo: "13 - IVRCL.png",
	},
	{
		id: "c13",
		name: "SPML Engineering",
		logo: "13 - SPML Engineering.png",
	},
];

export default clientsData;
