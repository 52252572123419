import React from "react";
import { Link } from "react-router-dom";
const Accolades = () => {
	return (
		<>
			<section
				className='breadcrumb-header'
				id='page'
				style={{
					backgroundImage: "url(assets/images/header/02_header.jpg)",
				}}>
				<div className='overlay'></div>
				<div className='container'>
					<div className='row'>
						<div className='col-md-8'>
							<div className='banner'>
								<h1>Accolades</h1>
								<ul>
									<li>
										<Link to='/'>Home</Link>
									</li>
									<li>
										<i className='fas fa-angle-right'></i>
									</li>
									<li>Accolades and initiatives</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='about-us py-100-70' id='start'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='about-us-text-box'>
								<div className='sec-title'>
									<h2>Accolades</h2>
									<p className='sec-explain'>
										TradeIndia.com awarded QMI ‘Long Standing Honourable Member’ HDFC Bank Jodhpur awarded QMI for its continued patronage and support Dun & Bradstreet Global Database Partner Fortune India Featured - An Organisation that is the{" "}
										<a href='https://www.fortuneindia.com/pdf/october-2022.pdf' target='_blank' rel='noreferrer' className='accolades-link'>
											Epitome of Trust & Reliability in the IT Industry
										</a>
										.
									</p>
									<figure>
										<img className='banner' src='assets/images/about-us/accolades.jpg' alt='banner' />
										<figcaption>Mr. Kamal Mehta (MD - Qualitech Group) - Hon'ble speaker at Pashchimi Rajasthan Udyog Hastshilp Utsav</figcaption>
									</figure>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='products'>
				<div className='container'>
					<div className='products-details-content'>
						<div className='row'>
							<div className='col-md-6'>
								<div className='img-box'>
									<img className='img-fluid gallery-item-img' src='assets/images/shop/01_shop.jpg' alt='Green Initiatives' />
								</div>
							</div>
							<div className='col-md-6'>
								<div className='text-box'>
									<h3 className='title-product'>Green Initiatives</h3>
									<p className='item-explain  sec-explain-darkgray'>In today’s day and age with increasing global concerns, it is pivotal to operate with mindfulness and sustainability. We at QMI, are dedicated towards strengthening the environment & thus has led to extensive development and dependency on solar energy for power consumption, reducing emissions and carbon footprint. We take pride in achieving 75% efficiency of our total energy consumption.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='products'>
				<div className='container'>
					<div className='products-details-content'>
						<div className='row'>
							<div className='col-md-6'>
								<div className='img-box'>
									<img className='img-fluid gallery-item-img' src='assets/images/shop/01_shop.jpg' alt='SOCIAL Initiatives' />
								</div>
							</div>
							<div className='col-md-6'>
								<div className='text-box'>
									<h3 className='title-product'>Social Initiatives</h3>
									<p className='item-explain sec-explain-darkgray'>
										As a business, it is also crucial to reflect our commitment to societal progress and by <strong>Qualitech Charitable Trust</strong>, we demonstrate our dedication to societal progress, positive change, and fostering inclusivity as a business, particularly by empowering and uplifting underprivileged communities.
									</p>
									<ul className='about-us-core-list'>
										<li className='item'>
											<i className='fas fa-check'></i>
											<h4>Education - Helping in organizing free workshops for engineers to promote technology and manufacturing industry locally</h4>
										</li>
										<li className='item'>
											<i className='fas fa-check'></i>
											<h4>Medical & health - Donation for testing machines to local hospitals</h4>
										</li>
										<li className='item'>
											<i className='fas fa-check'></i>
											<h4>Food - Childcare foundations or for newborn babies and mothers</h4>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Accolades;
