import { Link } from "react-router-dom";

import Hero from "./home/Hero";
import AboutUs from "./home/AboutUs";
import Services from "./home/Services";
import Team from "./home/Team";
import Provides from "./home/Provides";
import Statistic from "./home/Statistic";
import Products from "./home/Products";
import Testimonial from "./home/Testimonial";
import Clients from "./home/Clients";

export const Home = (props) => {
	return (
		<>
			<Hero />
			<AboutUs />
			<Services />
			<Products />
			<Team />
			<Clients />
			<Provides />
			<Statistic />
			<Testimonial />
			<div className='scroll-up'>
				<a className='move-section' href='#page'>
					<i className='fas fa-long-arrow-alt-up'></i>
				</a>
			</div>
		</>
	);
};
