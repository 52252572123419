import React from "react";
import { Link } from "react-router-dom";
const UnmatchedCompetence = () => {
	return (
		<>
			<section
				className='breadcrumb-header'
				id='page'
				style={{
					backgroundImage: "url(assets/images/header/02_header.jpg)",
				}}>
				<div className='overlay'></div>
				<div className='container'>
					<div className='row'>
						<div className='col-md-8'>
							<div className='banner'>
								<h1>Unmatched Competence</h1>
								<ul>
									<li>
										<Link to='/'>Home</Link>
									</li>
									<li>
										<i className='fas fa-angle-right'></i>
									</li>
									<li>Unmatched Competence</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='about-us py-100' id='start'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='about-us-text-box'>
								<div className='sec-title'>
									<h2>Our Expertise </h2>
									<p className='sec-explain'>What sets us apart from our competition is our ability to handle large projects with high precision, efficient management of hundreds of components with equally challenging assembly and installation processes.</p> <p className='sec-explain'>Our comprehensive designing, planning, scheduling, and execution according to multi-level requirements, including design, location, timing, and budget, makes us a preferred choice for clients looking for best in class metal production services.</p>
									<p className='sec-explain'> With experience in handling large projects with requirements for MS and DI manufacturing, QMI is committed to providing the best possible solutions to meet their client's unique needs.</p>
									<p className='sec-explain'> Furthermore, our focus on automation in the process has helped us deliver consistent and efficient results over the last 25+ years.</p>
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='about-us-text-box'>
								<div className='sec-title'>
									<h2>Process</h2>
									<p className='sec-explain'>At QMI, our mission is to employ technology to foster thriving businesses and communities through innovation and growth. We place a great value on establishing enduring relationships with our customers and providing them with the best products and services possible giving them competitive pricing advantage.</p>
									<p className='sec-explain'>With a vision of becoming a dominant force in the Indian metal manufacturing market, at QMI, we continually provide our clients with high-quality goods and services. By harnessing modern technologies and automating our operations we seek to further improve our efficiency, reduce waste, and drive overall growth.</p>
									<p className='sec-explain'> Being planet conscious is a key component in all our operations at QMI. We believe that sustainable growth benefits both the environment and our organization. We are constantly looking for ways to reduce our influence on the environment and our carbon footprint.</p>
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='about-us-text-box'>
								<div className='sec-title'>
									<h2>Values</h2>
									<p className='sec-explain'>Trust, Transparency and Efficiency in everything we do, that’s our constant endeavor at QMI.</p>
									<p className='sec-explain'>At QMI, we work with trust, honesty, and transparency in everything we do. We are committed to providing our customers with products that match industrial standards and exceed their expectations. Our product range includes all sizes and standards of Mild/Carbon Steel and Ductile Iron pipes, fittings, and joints.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default UnmatchedCompetence;
