import React from "react";
import { Link } from "react-router-dom";
const Team = () => {
	return (
		<>
			<section
				className='breadcrumb-header'
				id='page'
				style={{
					backgroundImage: "url(assets/images/header/07_header.jpg)",
				}}>
				<div className='overlay'></div>
				<div className='container'>
					<div className='row'>
						<div className='col-md-10'>
							<div className='banner'>
								<h1>Meet Our Leadership Team</h1>
								<ul>
									<li>
										<Link to='/'>Home</Link>
									</li>
									<li>
										<i className='fas fa-angle-right'></i>
									</li>
									<li>Team Meet</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='about-us py-40-0' id='start'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='about-us-text-box'>
								<div className='sec-title'>
									<h2>Meet Our Leadership Team</h2>
									<p className='sec-explain sec-explain-darkgray'>What sets us apart from our competition is our ability to handle large projects with high precision, efficient management of hundreds of components with equally challenging assembly and installation processes.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='team-meet'>
				<div className='container'>
					<div className='products-details-content'>
						<div className='row'>
							<div className='col-md-4'>
								<div className='img-box'>
									<img className='img-fluid gallery-item-img' src='assets/images/team/Kamal_Mehta_ji_C.jpg' alt='Mr. Kamal Mehta' />
								</div>
							</div>
							<div className='col-md-8'>
								<div className='text-box'>
									<h3 className='title-product'>Mr. Kamal Mehta (Founder and Chairman)</h3>
									<p className='item-explain sec-explain-darkgray'> A technocrat with over 50 years of experience in the metal industry. Mr. Kamal is a Mechanical Engineer from NIT, Kurukshetra, who has always had a passion for contributing to and transforming the conventional metal industry.</p>
									<p className='item-explain sec-explain-darkgray'>With his expertise in foundry and fabrication for Iron & Steel, Mr. Kamal has been a driving force behind QMI's success. His focused foresight and strong leadership have helped the company find a niche in the industry. Kamal's extensive experience and knowledge of the metal industry have been instrumental in the growth and success of QMI.</p>
									<p className='item-explain sec-explain-darkgray'>Apart from his professional achievements, Mr. Kamal has also made significant contributions to social and industrial welfare. He has served as the President and member of the Marudhara Industrial Association, working tirelessly to promote and develop the industrial sector in the region. As the Founder and Chairman of QMI, Mr. Kamal Mehta's exceptional leadership, vast knowledge, and expertise continue to drive the company towards greater heights of success.</p>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-md-4'>
								<div className='img-box'>
									<img className='img-fluid gallery-item-img' src='assets/images/team/Ankit_Mehta_C.jpg' alt='Ankit Mehta' />
								</div>
							</div>
							<div className='col-md-8'>
								<div className='text-box'>
									<h3 className='title-product'>Ankit Mehta (CEO)</h3>
									<p className='item-explain sec-explain-darkgray'>A technophile professional and alumnus of Warwick Business School with 8+ years of experience in metal manufacturing. He is responsible for driving innovation through data intelligence, business process automation to scale and digital transformation at Qualitech Metal Industries (QMI). Ankit's expertise in AI/ML has enabled QMI to introduce new technologies and stay at the forefront of the industry.</p>
									<p className='item-explain sec-explain-darkgray'>With his expertise, Ankit has introduced new technologies to enhance the quality of products and services at QMI. He has played a key role in the development of digital solutions and automation of processes, enabling QMI to stay at the forefront of the metal manufacturing industry. As a crucial member of the QMI team, Ankit Mehta's innovative mindset and technological know-how continue to drive the company's growth and success</p>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-md-4'>
								<div className='img-box'>
									<img className='img-fluid gallery-item-img' src='assets/images/team/Prateek_Mehta_C.jpg' alt='Pratik Mehta' />
								</div>
							</div>
							<div className='col-md-8'>
								<div className='text-box'>
									<h3 className='title-product'>Prateek Mehta (COO)</h3>
									<p className='item-explain sec-explain-darkgray'>A seasoned professional with over a decade (12 years) of experience in the metal industry, and an additional 6+ years of experience in handicrafts. Pratik comes from a technology background and has garnered vast knowledge and expertise in supply chain management.</p>
									<p className='item-explain sec-explain-darkgray'>Pratik, since a decade, has been working towards optimizing the business processes of Qualitech Metal Industries (QMI) right from procurement to deliveries and scale. With his expertise, the company has implemented strong policies and processes to ensure the smooth operations of the business and timely delivery of high-quality products to customers. Pratik's extensive experience and knowledge of the metal industry and supply chain management have been crucial in the growth and success of QMI. He has brought in a fresh perspective and innovative ideas to streamline the company's operations, increase efficiency, and improve customer satisfaction.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Team;
