import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Hero = () => {
	return (
		<>
			<section className='header' id='page'>
				<div className='header-carousel'>
					<OwlCarousel
						items={3}
						autoplayTimeout={400}
						margin={30}
						smartSpeed={1000}
						autoplayHoverPause
						dots={false}
						mouseDrag
						touchDrag
						navText={['<i class="ar-icons-left-chevron"></i>', '<i class="ar-icons-right-chevron"></i>']}
						dotClass={["container", "owl-dots"]}
						animateIn='fadeIn'
						animateOut='fadeOut'
						responsive={{
							0: {
								items: 1,
							},
							768: {
								items: 1,
							},
							991: {
								items: 1,
							},
						}}
						nav
						loop
						autoPlay>
						<div
							className='sec-hero display-table'
							style={{
								backgroundImage: "url(assets/images/header/01_header.jpg)",
							}}>
							<div className='table-cell'>
								<div className='overlay'></div>
								<div className='container'>
									<div className='row'>
										<div className='col-lg-8'>
											<div className='banner'>
												<h1 className='handline'>Qualitech Metal Industries</h1>
												<p className='about-website'>The TRUSTED partner in metal pipes manufacturing and fabrication.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							className='sec-hero display-table'
							style={{
								backgroundImage: "url(assets/images/header/03_header.jpg)",
							}}>
							<div className='table-cell'>
								<div className='overlay'></div>
								<div className='container'>
									<div className='row'>
										<div className='col-lg-8'>
											<div className='banner'>
												<h1 className='handline'>Precision in every weld</h1>
												<p className='about-website'>Unleashing INNOVATION in the metal pipe manufacturing industry.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							className='sec-hero display-table'
							style={{
								backgroundImage: "url(assets/images/header/02_header.jpg)",
							}}>
							<div className='table-cell'>
								<div className='overlay'></div>
								<div className='container'>
									<div className='row'>
										<div className='col-lg-8'>
											<div className='banner'>
												<h1 className='handline'>Building Strength and convictions</h1>
												<p className='about-website'>Delivering HIGH-QUALITY pipes for a stronger and everlasting engineering excellence.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</OwlCarousel>
				</div>
			</section>
		</>
	);
};

export default Hero;
