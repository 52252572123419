import { Link } from "react-router-dom";

export const Footer = () => {
	return (
		<footer className='footer'>
			<div className='copyright'>
				<div className='container'>
					<Link to='/'>
						<img src={"assets/images/logo/footer-logo.png"} alt='footer-logo' />
					</Link>
					<div>
						<ul>
							<li>
								<Link to='#'>Terms &amp; Conditions </Link>
							</li>
							<li>
								<Link to='#'>Privacy Policy</Link>
							</li>
							<li>
								<Link to='#'>Sitemap</Link>
							</li>
						</ul>
						<small>@ 2023 Qualitech.</small>
					</div>
				</div>
			</div>
		</footer>
	);
};
