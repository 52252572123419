import React from "react";
import { Link, useLocation } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";

export const Header = () => {
	const activeRoute = useLocation();

	return (
		<header className='all-navbar fixed-top'>
			<nav className='nav-bar'>
				<div className='container'>
					<div className='content-box d-flex align-items-center justify-content-between'>
						<div className='logo'>
							<Link className='logo-nav' to='/'>
								<img className='img-fluid one' src='assets/images/logo/logo.png' alt='01 Logo' />
								<img className='img-fluid two' src='assets/images/logo/logo.png' alt='02 Logo' />
							</Link>
							<a href='#open-nav-bar-menu' className='open-nav-bar'>
								<span></span>
								<span></span>
								<span></span>
							</a>
						</div>
						<div className='nav-bar-links' id='open-nav-bar-menu'>
							<ul className='level-1'>
								<li className='item-level-1'>
									<Link className={`link-level-1${activeRoute.pathname === "/" ? " color-active" : ""}`} to='/'>
										Home
									</Link>
								</li>
								<li className='item-level-1'>
									<Link className={`link-level-1${activeRoute.pathname.includes("about-us") ? " color-active" : ""}`} to='/about-us'>
										About Us
									</Link>
								</li>
								<li className='item-level-1'>
									<Link to='/products' className={`link-level-1${activeRoute.pathname.includes("products") ? " color-active" : ""}`}>
										Products
									</Link>
								</li>
								<li className='item-level-1'>
									<Link to='/team-meet' className='link-level-1'>
										Our Team
									</Link>
								</li>
								<li className='item-level-1'>
									<Link to='/coating' className='link-level-1'>
										Coating
									</Link>
								</li>
								<li className='item-level-1'>
									<Link to='/contact' className={`link-level-1${activeRoute.pathname.includes("contact") ? " color-active" : ""}`}>
										Contact Us
									</Link>
								</li>
							</ul>
						</div>
						<ul className='nav-bar-tools d-flex align-items-center justify-content-between'>
							{/* <li className="item">
                <span className="search-icon open">
                  <i className="fas fa-search"></i>
                </span>
              </li> */}
							<li className='item phone'>
								<div className='nav-bar-contact'>
									{/* <i className="ar-icons-phone"></i> */}
									{/* <div className="content-box">
                    <span>Phone Number</span>
                    <a href="tel:01212843661">+91 291 274 1005</a>
                  </div> */}
									{activeRoute.pathname === "/" && (
										<AnchorLink className='enquire-now' href='#quote-box' offset='150'>
											<span>Enquire Now</span>
										</AnchorLink>
									)}
								</div>
							</li>
							{/*<li className='item'>
								<span className='menu-icon open'>
									<i className='fas fa-list'></i>
								</span>
							</li>
                */}
						</ul>
					</div>
				</div>
			</nav>
		</header>
	);
};
