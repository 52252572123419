import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import clientsData from "../../data/clients";
const Clients = () => {
	const [clients, setClients] = useState(clientsData);
	const clientsList = clients.map((client) => {
		return (
			<div className='services-carousel-item' key={client.id}>
				<img src={`assets/images/clients/${client.logo}`} className='services-carousel-item-img' alt={client.name} />
				<span>{client.name}</span>
			</div>
		);
	});
	return (
		<>
			<section className='clients py-40'>
				<div className='container'>
					<div className='sec-title'>
						<div className='row'>
							<div className='col-lg-12'>
								<h3 className='section-heading-blue'>Our Clients</h3>
								{/*<p className='sec-explain'>Ullamco minim ullamco cupidatat in proident enim.</p> */}
							</div>
						</div>
					</div>
					<div className='services-carousel'>
						<OwlCarousel
							loop={false}
							margin={20}
							smartSpeed={1000}
							autoplay={true}
							nav={true}
							autoplayHoverPause
							mouseDrag
							touchDrag
							dot={false}
							navText={['<i class="ar-icons-left-chevron"></i>', '<i class="ar-icons-right-chevron"></i>']}
							responsive={{
								0: {
									items: 1,
								},
								768: {
									items: 3,
								},
								991: {
									items: 4,
								},
								1199: {
									items: 5,
								},
								1399: {
									items: 6,
								},
							}}>
							{clientsList}
						</OwlCarousel>
					</div>
				</div>
			</section>
		</>
	);
};

export default Clients;
