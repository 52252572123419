import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { AboutUs } from "./components/about-us";
import { Coating } from "./components/coating";
import { Contact } from "./components/contact";
import { Footer } from "./components/footer";
import { Header } from "./components/header";
import { Home } from "./components/home";
import { MenuBox } from "./components/menu-box";
import { Products } from "./components/products";
import { Projects } from "./components/projects";
import UnmatchedCompetence from "./components/home/UnmatchedCompetence";
import Accolades from "./components/home/Accolades";
import Team from "./components/Team";
/*import { Blog } from "./components/blog";
import { Careers } from "./components/careers"; 
import { Faq } from "./components/faq";
import { SearchBox } from "./components/search-box";
*/
const App = () => {
	return (
		<>
			<HashRouter>
				<Header />
				{/*<SearchBox />*/}
				<MenuBox />
				<Routes>
					<Route path='/' Component={Home} />
					<Route path='/about-us' Component={AboutUs} />
					<Route path='/products' Component={Products} />
					<Route path='/projects' Component={Projects} />
					<Route path='/coating' Component={Coating} />
					<Route path='/contact' Component={Contact} />
					<Route path='/unmatched-competence' Component={UnmatchedCompetence} />
					<Route path='/accolades-and-initiatives' Component={Accolades} />
					<Route path='/team-meet' Component={Team} />
					{/*<Route path='/blog' Component={Blog} />
					<Route path='/careers' Component={Careers} />
					<Route path='/faq' Component={Faq} /> */}
				</Routes>
				<Footer />
			</HashRouter>
		</>
	);
};

export default App;
