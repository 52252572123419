import React from "react";
import { Link } from "react-router-dom";
const Team = () => {
	return (
		<>
			<section className='team py-20'>
				<div className='container'>
					<div className='sec-title'>
						<div className='row'>
							<div className='col-lg-12'>
								<h3 className='section-heading-orange text-center'>Meet the Team</h3>
							</div>
							<div className='col-lg-12 text-center'>
								<p className='sec-explain'>We understand that our success is not ours alone, and we rely on the collective intelligence and contribution of all our members.</p>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-6 col-lg-4 g-0'>
							<div className='team-box'>
								<div className='user'>
									<div className='img-box'>
										<img className='img-fluid' src='assets/images/team/Kamal_Mehta_ji_C.jpg' alt='Kamal Mehata' />
									</div>
									<div className='img-box carbon'>
										<img className='img-fluid' src='assets/images/team/Kamal_Mehta_ji_BnW.jpg' alt='Kamal Mehata' />
									</div>
								</div>
								<div className='text-box text-center'>
									<h5>
										<a href='01_team.html'>Mr. Kamal Mehta</a>
									</h5>
									<span>Founder and Chairman</span>
								</div>
							</div>
						</div>
						<div className='col-md-6 col-lg-4 g-0'>
							<div className='team-box'>
								<div className='user'>
									<div className='img-box'>
										<img className='img-fluid' src='assets/images/team/Ankit_Mehta_C.jpg' alt='Ankit mehata' />
									</div>
									<div className='img-box carbon'>
										<img className='img-fluid' src='assets/images/team/Ankit_Mehta_BnW.jpg' alt='Ankit mehata' />
									</div>
								</div>
								<div className='text-box text-center'>
									<h5>
										<a href='01_team.html'>Ankit Mehta</a>
									</h5>
									<span>CEO</span>
								</div>
							</div>
						</div>
						<div className='col-md-6 col-lg-4 g-0'>
							<div className='team-box'>
								<div className='user'>
									<div className='img-box'>
										<img className='img-fluid' src='assets/images/team/Prateek_Mehta_C.jpg' alt='Pratik Mehata' />
									</div>
									<div className='img-box carbon'>
										<img className='img-fluid' src='assets/images/team/Prateek_Mehta_BnW.jpg' alt='Pratik Mehata' />
									</div>
								</div>
								<div className='text-box text-center'>
									<h5>
										<a href='01_team.html'>Prateek Mehta</a>
									</h5>
									<span>COO</span>
								</div>
							</div>
						</div>
						<div className='services-action'>
							<Link to='/team-meet' class='btn-1 btn-3 link rounded-pill'>
								<span>Read More</span>
							</Link>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Team;
