export const Projects = () => {
	return (
		<>
			<section
				className='breadcrumb-header'
				id='page'
				style={{
					backgroundImage: "url(assets/images/header/01_breadcrumb-header.jpg)",
				}}>
				<div className='overlay'></div>
				<div className='container'>
					<div className='row'>
						<div className='col-md-8'>
							<div className='banner'>
								<h1>Our Projects</h1>
								<ul>
									<li>
										<a href='index.html'>Home</a>
									</li>
									<li>
										<i className='fas fa-angle-right'></i>
									</li>
									<li>Our Projects</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='projects py-100'>
				<div className='container'>
					<div className='sec-title'>
						<div className='row'>
							<div className='col-lg-5'>
								<h2>We work with global Industries!</h2>
								<h3>Qualitech Completed Some Project For Our Foreign Clients</h3>
							</div>
							<div className='col-lg-5 d-flex align-items-center'>
								<p className='sec-explain'>Qualitech Are A Industry &amp; Manufacturing Services Provider Institutions. Suitable For Factory, Manufacturing, Industry, Engineering, Construction And Any Related Industry Care Field.</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='project-details'>
				<div className='container'>
					<div className='single-services-content-box'>
						<h2>Project 1: Qualitech Completed Some Project For Our Foreign Clients</h2>
						<p>Qualitech Are A Industry &amp; Manufacturing Services Provider Institutions. Suitable For Factory, Manufacturing, Industry, Engineering, Construction And Any Related Industry Care Field.</p>
						<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
						<div className='single-services-img-box'>
							<img className='img-fluid' src='assets/images/services/01_services-bg.jpg' alt='Team Details' />
						</div>
						<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
						<h3>Features</h3>
						<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
						<ul className='about-us-core-list'>
							<li className='item'>
								<i className='fas fa-check'></i>
								<h4>Petroleum &amp; Gas Energy</h4>
							</li>
							<li className='item'>
								<i className='fas fa-check'></i>
								<h4>Constraction Of Engineering</h4>
							</li>
							<li className='item'>
								<i className='fas fa-check'></i>
								<h4>Basic &amp; IndustrialChemicals</h4>
							</li>
							<li className='item'>
								<i className='fas fa-check'></i>
								<h4>Mechanical Engineering</h4>
							</li>
							<li className='item'>
								<i className='fas fa-check'></i>
								<h4>Automotive Manufacturing</h4>
							</li>
							<li className='item'>
								<i className='fas fa-check'></i>
								<h4>Bridge Constraction</h4>
							</li>
							<li className='item'>
								<i className='fas fa-check'></i>
								<h4>And More Of The Best services</h4>
							</li>
						</ul>
					</div>
				</div>
			</section>

			<section className='project-details py-100'>
				<div className='container'>
					<div className='single-services-content-box'>
						<h2>Project 2: Qualitech Completed Some Project For Our Foreign Clients</h2>
						<p>Qualitech Are A Industry &amp; Manufacturing Services Provider Institutions. Suitable For Factory, Manufacturing, Industry, Engineering, Construction And Any Related Industry Care Field.</p>
						<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
						<div className='single-services-img-box'>
							<img className='img-fluid' src='assets/images/services/01_services-bg.jpg' alt='Team Details' />
						</div>
						<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
						<h3>Features</h3>
						<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
						<ul className='about-us-core-list'>
							<li className='item'>
								<i className='fas fa-check'></i>
								<h4>Petroleum &amp; Gas Energy</h4>
							</li>
							<li className='item'>
								<i className='fas fa-check'></i>
								<h4>Constraction Of Engineering</h4>
							</li>
							<li className='item'>
								<i className='fas fa-check'></i>
								<h4>Basic &amp; IndustrialChemicals</h4>
							</li>
							<li className='item'>
								<i className='fas fa-check'></i>
								<h4>Mechanical Engineering</h4>
							</li>
							<li className='item'>
								<i className='fas fa-check'></i>
								<h4>Automotive Manufacturing</h4>
							</li>
							<li className='item'>
								<i className='fas fa-check'></i>
								<h4>Bridge Constraction</h4>
							</li>
							<li claclassNamess='item'>
								<i className='fas fa-check'></i>
								<h4>And More Of The Best services</h4>
							</li>
						</ul>
						<h3>Video Presentation</h3>
						<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
						<div className='video-presentation'>
							<div className='overlay'></div>
							<div className='presentation-box'>
								<iframe width='100%' height='450' src='https://www.youtube.com/embed/1hUrjHYiAWI?playlist=1hUrjHYiAWI&loop=1&mute=1' title='YouTube video player' frameBorder='0' allow='autoplay;loop; muted;' allowFullScreen></iframe>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
