export const Coating = () => {
	return (
		<>
			<section
				className='breadcrumb-header'
				id='page'
				style={{
					backgroundImage: "url(assets/images/header/02_header.jpg)",
				}}>
				<div className='overlay'></div>
				<div className='container'>
					<div className='row'>
						<div className='col-md-8'>
							<div className='banner'>
								<h1>Coating</h1>
								<ul>
									<li>
										<a href='index.html'>Home</a>
									</li>
									<li>
										<i className='fas fa-angle-right'></i>
									</li>
									<li>Coating</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='about-us py-20-0' id='start'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='about-us-text-box'>
								<div className='sec-title'>
									<p className='coating-para'>PU COATING</p>
									<p className='sec-explain sec-explain-darkgray'>Polyurethane (PU) coating on MS (Mild Steel) pipes provides 
									robust protection against corrosion and abrasion. 
									This resilient coating forms a durable barrier, extending the lifespan of the pipes in harsh environments.
									 PU-coated MS pipes are ideal for various industrial applications, 
									 offering excellent resistance to chemicals and weathering. 
									 The coating enhances the pipes' structural integrity, ensuring longevity and reliability in diverse settings.</p>
									<img src='assets/images/coating/PU-COATING.jpg' />
									<br />									
								</div>
								<div className='sec-title'>
									<p className='coating-para'>FBE Coating</p>
									<p className='sec-explain sec-explain-darkgray'>Fusion-bonded epoxy (FBE) coating is a corrosion-resistant layer applied to metal pipelines, 
									ensuring durability and longevity in harsh environments. This coating process involves melting epoxy resin and applying it electrostatically to the prepared surface of the pipeline, where it fuses into a hard, protective layer. 
									FBE coatings offer exceptional adhesion, chemical resistance, and flexibility, guarding against corrosion, abrasion, and damage. Widely used in the oil, gas, and water industries, FBE coatings safeguard pipelines, 
									reducing maintenance costs and extending their service life, ensuring reliable and efficient transportation of fluids over extended periods of time.
									</p><img src='assets/images/coating/FBE-COATING.jpg' />
									<br />									
								</div>
								<div className='sec-title'>
									<p className='coating-para'>CEMENT GROUTING</p>
									<p className='sec-explain sec-explain-darkgray'>Cement grouting on metal pipelines involves the injection of a cement-based mixture into the annular space between the pipeline and its casing. This process is crucial for stabilizing, sealing, and reinforcing the pipeline, preventing leakages, corrosion, and structural damage. The grout creates a strong bond, enhancing the pipeline's structural integrity and protecting it against external elements. Through careful application and precise techniques, cement grouting ensures a durable and resilient infrastructure, prolonging the lifespan and reliability of metal pipelines in various industries like construction, oil and gas, and infrastructure development.
</p><img src='assets/images/coating/CEMENT-GUINTING.jpg' />
									<br />									
								</div>
								<div className='sec-title'>
									<p className='coating-para'>CEMENT MOTOR LINING</p>
									<p className='sec-explain sec-explain-darkgray'>Cement–mortar-lined and cement–mortar-coated steel pipe combines the physical strength of steel with the protective qualities of cement mortar. The lining, applied centrifugally, creates a smooth, dense finish that protects the pipe from tuberculation and provides a measure of corrosion protection.

</p><img height='400' width='500' src='assets/images/coating/centar-motor-lining.jpg' />
									<br />									
								</div>
								<div className='sec-title'>
									<p className='coating-para'>TAPE COATING</p>
									<p className='sec-explain sec-explain-darkgray'>In general, a tape coating is a laminate system consisting of an outer backing material (e.g., polyethylene (PE), PVC, woven polyolefin) and an inner adhesive mastic (e.g., petrolatum grease, rubber-based compounds) that are spirally wrapped around the pipe ensuring a high degree of overlap between turns.This coating serves as a barrier against moisture, chemicals, and environmental factors, safeguarding the metal from corrosion and extending its lifespan.
</p><img src='assets/images/coating/TAPE-COATING.png' />
									<br />									
								</div>
								<div className='sec-title'>
									<p className='coating-para'>COAL TAR COATING</p>
									<p className='sec-explain sec-explain-darkgray'>Coal tar coating is a durable protective layer applied to metal pipelines, renowned for its corrosion resistance and weatherproofing capabilities. Composed of refined coal tar pitch, it forms a robust barrier, shielding against moisture, chemicals, and abrasion, extending the lifespan of pipelines in harsh environments. Its viscous nature allows for easy application, adhering firmly to surfaces and providing exceptional insulation. Widely used in the oil, gas, and marine industries, coal tar coatings effectively safeguard against rust and deterioration, ensuring the integrity and longevity of the vital infrastructure they preserve.
</p><img src='assets/images/coating/COAL-TAR-COATING.png' />
									<br />									
								</div>
								<div className='sec-title'>
									<p className='coating-para'>EPOXY COATING</p>
									<p className='sec-explain sec-explain-darkgray'>Epoxy pipe coating is a method of coating the inside of pipes, tanks, and other vessels with a layer of epoxy resin. This coating helps to protect the interior surfaces of the pipes from corrosion and chemical damage, as well as improve the flow of fluids through the pipes. The epoxy resin is applied to the interior surface of the pipe using a spray or brush, and it is allowed to cure and harden. The resulting coating is a smooth and durable layer that is resistant to chemicals and abrasion.
</p><img src='assets/images/coating/EPOXY-COATING.jpg' />
									<br />									
								</div>
								<div className='row'>
									<div className='col-sm-12'>
										<p className='providing'>Why Choose QMI</p>
										<ul className='about-us-core-list tiles'>
											<li className='item'>
												<div>
													<i className='ar-icons-idea'></i>
												</div>
												<h4>Advanced Process For Manufacturing and Fabrication</h4>
											</li>
											<li className='item'>
												<div>
													<i className='ar-icons-foreman'></i>
												</div>
												<h4>Best Practises and Customer Orientation</h4>
											</li>
											<li className='item'>
												<div>
													<i className='ar-icons-electricity'></i>
												</div>
												<h4>Competitive Pricing</h4>
											</li>
											<li className='item'>
												<div>
													<i className='ar-icons-factory'></i>
												</div>
												<h4>Timely Delivery and Quality Assurance</h4>
											</li>
											<li className='item'>
												<div>
													<i className='ar-icons-worker'></i>
												</div>
												<h4>Global Standards and Specifications - US, ISI and BS</h4>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
