import React, { useState } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import projects from "../../data/products-list";

const Products = () => {
	const [products, setProducts] = useState(projects);
	const productList = products.map((prod) => {
		return (
			<div className='projects-item' key={prod.id}>
				<div className='img-box'>
					<img className='img-fluid projects-item-img' src={`assets/images/projects/${prod.images[0]}`} alt={prod.name} />
				</div>
				<div className='text-box text-center'>
					<h5>{prod.name}</h5>
				</div>
			</div>
		);
	});
	return (
		<>
			<section className='product py-20'>
				<div className='container'>
					<div className='sec-title'>
						<div className='row'>
							<div className='col-lg-5'>
								<h3 className='section-heading-blue'>Products</h3>
								<h4 className='product-subheading'>Manufactured Using</h4>
							</div>
						</div>

						<div className='row'>
							<div className='col-lg-6 d-flex align-items-center'>
								<ul className='about-us-core-list'>
									<li className='item'>
										<i className='fas fa-check'></i>
										<h4 className='sec-explain-darkgray'>M.S. plates tested as per IS-2062/BS prior to use.</h4>
									</li>
									<li className='item'>
										<i className='fas fa-check'></i>
										<h4 className='sec-explain-darkgray'>Threaded fasteners conforming to IS-1363-1367 being used.</h4>
									</li>
									<li className='item'>
										<i className='fas fa-check'></i>
										<h4 className='sec-explain-darkgray'>All the products are hydraulically tested to required test pressure before dispatch.</h4>
									</li>
								</ul>
							</div>
							<div className='col-lg-6 d-flex align-items-center'>
								<ul className='about-us-core-list'>
									<li className='item'>
										<i className='fas fa-check'></i>
										<h4 className='sec-explain-darkgray'>Complete manufacturing facility available to manufacture products up to 3000 mm diameter.</h4>
									</li>
									<li className='item'>
										<i className='fas fa-check'></i>
										<h4 className='sec-explain-darkgray'>All the products and materials are subject to third party inspection like C.E.I.L., R.I.T.E.S., P.D.I.L., etc.</h4>
									</li>
									<li className='item'>
										<i className='fas fa-check'></i>
										<h4 className='sec-explain-darkgray'>All the in house facilities available for fusion based epoxy powder coatings.</h4>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className='projects-carousel'>
						<OwlCarousel
							loop={false}
							margin={30}
							smartSpeed={1000}
							autoplay={false}
							nav={true}
							autoplayHoverPause
							mouseDrag
							touchDrag
							navText={['<i class="ar-icons-left-chevron"></i>', '<i class="ar-icons-right-chevron"></i>']}
							responsive={{
								0: {
									items: 1,
								},
								768: {
									items: 2,
								},
								991: {
									items: 3,
								},
								1199: {
									items: 3,
								},
								1399: {
									items: 4,
								},
							}}>
							{productList}
						</OwlCarousel>
						<div className='services-action'>
							<Link to='/products' class='btn-1 btn-3 link rounded-pill'>
								<span>Explore Products</span>
							</Link>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Products;
