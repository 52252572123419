import React from "react";

const AboutUs = () => {
	return (
		<>
			<section className='about-us py-20-0' id='start'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='about-us-text-box'>
								<div className='sec-title'>
									<h3 className='section-heading-blue'>QMI: Forging excellence with the power of precision</h3>
									<p className='sec-explain sec-explain-darkgray'>Incepted in 1976, we began our journey with manufacturing of high quality CI casting products and later expanded in MS Pipes, Joints, Flanges and more. Further scaling its operations to grow into a conglomerate, managing a group of companies viz IT, Handcrafted Furniture &amp; HR services.</p>
									<br />
									<p className='sec-explain sec-explain-darkgray'>Over the decades we have gained vast experience and in depth knowledge of working in vastly different and challenging environments and adapting to the ever evolving needs of our clients across the globe. Our remarkable growth is the result of relentless efforts of our highly skilled and dedicated team of managers, engineers, supervisors, and skilled labor forces that encapsulate the company&#39;s philosophy and values.</p>
									<br />
									<p className='sec-explain sec-explain-darkgray'>We operate all across the globe with our head office in Jodhpur, Rajasthan. Our other offices are in Bangalore, Mumbai and USA.</p>
								</div>
								<div className='row'>
									<div className='col-sm-12'>
										<p className='providing'>Why Choose QMI</p>
										<ul className='about-us-core-list tiles'>
											<li className='item'>
												<div>
													<i className='ar-icons-idea'></i>
												</div>
												<h4>Advanced Process For Manufacturing and Fabrication</h4>
											</li>
											<li className='item'>
												<div>
													<i className='ar-icons-foreman'></i>
												</div>
												<h4>Best Practises and Customer Orientation</h4>
											</li>
											<li className='item'>
												<div>
													<i className='ar-icons-electricity'></i>
												</div>
												<h4>Competitive Pricing</h4>
											</li>
											<li className='item'>
												<div>
													<i className='ar-icons-factory'></i>
												</div>
												<h4>Timely Delivery and Quality Assurance</h4>
											</li>
											<li className='item'>
												<div>
													<i className='ar-icons-worker'></i>
												</div>
												<h4>Global Standards and Specifications - US, ISI and BS</h4>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default AboutUs;
