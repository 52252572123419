import React from "react";
import { useSpring, config, animated } from "react-spring";

function AnimatedNumber(props) {
	const startCounting = props.numref?.isIntersecting;
	const { number } = useSpring({
		from: { number: 0 },
		number: startCounting ? props.done : 0,
		delay: 200,
		config: config.molasses,
	});

	return (
		<div className='counter statistic-counter'>
			<animated.div>{number.to((n) => new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(n?.toFixed()))}</animated.div> <span>+</span>
		</div>
	);
}

export default AnimatedNumber;
