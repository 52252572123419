import React, { useRef } from "react";
import AnimatedNumber from "../AnimatedNumber";
import useIntersectionObserver from "../../useIntersectionObserver";

const Statistic = () => {
	const triggerRef = useRef();
	const dataRef = useIntersectionObserver(triggerRef, {
		freezeOnceVisible: false,
	});

	return (
		<>
			<div className='statistic'>
				<div ref={triggerRef} />
				<div className='container'>
					<div className='row'>
						<div className='col-sm-6 col-lg-4'>
							<div className='statistic-item'>
								<i className='ar-icons-checklist'></i>
								<div className='content'>
									<div ref={triggerRef} />
									<AnimatedNumber done={100} numref={dataRef} />
									<div className='counter-name'>Clients</div>
								</div>
							</div>
						</div>
						<div className='col-sm-6 col-lg-4'>
							<div className='statistic-item'>
								<i className='ar-icons-conveyor-2'></i>
								<div className='content'>
									<AnimatedNumber done={400} numref={dataRef} />
									<div className='counter-name'>Projects</div>
								</div>
							</div>
						</div>
						<div className='col-sm-6 col-lg-4'>
							<div className='statistic-item'>
								<i className='ar-icons-conveyor-3'></i>
								<div className='content'>
									<AnimatedNumber done={500000} numref={dataRef} />
									<div className='counter-name'>tonnes of steel manufactured</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Statistic;
