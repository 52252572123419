import React from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Services = () => {
	return (
		<>
			<section className='services'>
				<div className='bg-section'>
					<div className='overlay overlay-2'></div>
				</div>
				<div className='container'>
					<div className='sec-title'>
						<div className='row'>
							<div className='col-lg-12'>
								<h3 className='section-heading-orange'>Unmatched competence</h3>
								<p className='sec-explain'>QMI specializes in handling high-precision manufacturing of hundreds of components, coupled with challenging assembly and installation processes. We offer comprehensive designing, planning, scheduling, and execution solutions to meet multi-level requirements, including design, location, timing, and budget. QMI has a proven track record in handling large-scale projects with specific requirements for MS and DI manufacturing, while meeting the highest quality standards. Additionally, we are continuously innovating and implementing further automation in our manufacturing processes, which improves productivity, efficiency, and cost-effectiveness. With a focus on quality, expertise, and innovation, Qualitech Metal Industries is a reliable partner for all your requirements related to water supply projects.</p>
							</div>
						</div>
					</div>
					<div className='services-carousel'>
						<OwlCarousel
							loop={false}
							margin={20}
							smartSpeed={1000}
							autoplay={false}
							nav={true}
							autoplayHoverPause
							mouseDrag
							touchDrag
							navText={['<i className="ar-icons-left-chevron"></i>', '<i className="ar-icons-right-chevron"></i>']}
							responsive={{
								0: {
									items: 1,
								},
								768: {
									items: 2,
								},
								991: {
									items: 3,
								},
								1199: {
									items: 3,
								},
								1399: {
									items: 4,
								},
							}}>
							<div className='services-carousel-item'>
								<div className='services-carousel-item-img'>
									<i className='ar-icons-tank'></i>
								</div>
								<h4>Our Expertise</h4>
								<p className='sec-explain-darkgray'>What sets us apart from our competition is our ability to handle large projects with high precision, efficient management of hundreds of components with equally challenging assembly and installation processes.</p>
							</div>
							<div className='services-carousel-item'>
								<div className='services-carousel-item-img'>
									<i className='ar-icons-worker'></i>
								</div>
								<h4>Process</h4>
								<p className='sec-explain-darkgray'>At QMI, our mission is to employ technology to foster thriving businesses and communities through innovation and growth.</p>
							</div>
							<div className='services-carousel-item'>
								<div className='services-carousel-item-img'>
									<i className='ar-icons-factory'></i>
								</div>
								<h4>Values</h4>
								<p className='sec-explain-darkgray'>Trust, Transparency and Efficiency in everything we do, that’s our constant endeavor at QMI.</p>
							</div>
						</OwlCarousel>
						<div className='services-action'>
							<Link to='unmatched-competence' class='btn-1 btn-3 link rounded-pill'>
								<span>Read More</span>
							</Link>
						</div>
					</div>

					<div className='video-presentation'>
						<div className='presentation-box'>
							<iframe width='100%' height='450' src='https://www.youtube.com/embed/1hUrjHYiAWI?playlist=1hUrjHYiAWI&loop=1&mute=1' title='YouTube video player' frameBorder='0' allow='autoplay;loop; muted;' allowFullScreen></iframe>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Services;
