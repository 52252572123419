import products from "../data/products-list";

import ProductsSlider from "./ProductsSlider";
export const Products = () => {
	return (
		<>
			<section
				className='breadcrumb-header'
				id='page'
				style={{
					backgroundImage: "url(assets/images/header/02_header.jpg)",
				}}>
				<div className='overlay'></div>
				<div className='container'>
					<div className='row'>
						<div className='col-md-8'>
							<div className='banner'>
								<h1>Our Products</h1>
								<ul>
									<li>
										<a href='index.html'>Home</a>
									</li>
									<li>
										<i className='fas fa-angle-right'></i>
									</li>
									<li>Our Products</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='projects py-40-0'>
				<div className='container'>
					<div className='sec-title'>
						<div className='row'>
							<div className='col-lg-12'>
								<h3>Seamless Strength: Crafting Premium Metal Pipes for Infrastructure Projects</h3>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='products'>
				{products
					.filter((item) => item.title !== "")
					.map((product) => {
						let { images } = product;
						const imggs = <img src='`assets/images/projects/${product.table_image}`' />;
						return (
							<div className='container' key={product.id}>
								<div className='products-details-content'>
									<div className='row'>
										<div className='col-md-4'>
											<div className='img-box'>
												<ProductsSlider imageSet={images} />
											</div>
										</div>
										<div className='col-md-8'>
											<div className='text-box'>
												<h3 className='title-product'>{product.title}</h3>
												<p className='item-explain sec-explain-darkgray'>{product.info}</p>
												{ product.info1 && <p className='item-explain sec-explain-darkgray'>{product.info1}</p> }
												{ product.info2 && <p className='item-explain sec-explain-darkgray'>{product.info2}</p> }
												{ product.info3 && <p className='item-explain sec-explain-darkgray'>{product.info3}</p> }
												{ product.table_image && <img src='assets/images/projects/product-spec.png' /> }
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					})}
			</section>
		</>
	);
};
