import React from "react";
import { Link } from "react-router-dom";
import ContactUs from "./ContactUs";

const Provides = (props) => {
	return (
		<>
			<section className='provide'>
				<div className='bg-section'>
					<div className='overlay overlay-3'></div>
				</div>
				<div className='container'>
					<div className='sec-title'>
						<div className='row'>
							<div className='col-lg-5'>
								<h3 className='section-heading-orange'>Accolades</h3>
							</div>
						</div>
						<div className='row'>
							<div className='col-lg-6 d-flex align-items-start'>
								<ul className='about-us-core-list'>
									<li className='item'>
										<i className='fas fa-star'></i>
										<p className='sec-explain'>TradeIndia.com awarded QMI ‘Long Standing Honourable Member’ </p>
									</li>
									<li className='item'>
										<i className='fas fa-star'></i>
										<p className='sec-explain'>HDFC Bank Jodhpur awarded QMI for its continued patronage and support </p>
									</li>
									<li className='item'>
										<i className='fas fa-star'></i>
										<p className='sec-explain'>Dun & Bradstreet Global Database Partner</p>
									</li>
									<li className='item'>
										<i className='fas fa-star'></i>
										<p className='sec-explain'>
											Fortune India Featured - An Organisation that is the {""}
											<Link to='https://www.fortuneindia.com/pdf/october-2022.pdf' target='_blank' className='pdf_link'>
												Epitome of Trust & Reliability in the IT Industry.
											</Link>
										</p>
									</li>
								</ul>
							</div>
							<div className='col-lg-6 d-flex align-items-start'>
								<figure>
									<img className='banner' src='assets/images/about-us/accolades.jpg' alt='banner' />
									<figcaption>Mr. Kamal Mehta (MD - Qualitech Group) - Hon'ble speaker at Pashchimi Rajasthan Udyog Hastshilp Utsav</figcaption>
								</figure>
							</div>
						</div>
					</div>
					<div className='provide-core-list'>
						<div className='row'>
							<div className='col-md-6 col-lg-4 g-0'>
								<div className='blog-item'>
									<div className='text-box'>
										<a href='02_blog.html' className='title-blog'>
											<h5>Green Initiative</h5>
										</a>
										<p className='sec-explain-darkgray'>In today’s day and age with increasing global concerns, it is pivotal to operate with mindfulness and sustainability. We at QMI, are dedicated towards strengthening the environment & thus has led to extensive development and dependency on solar energy for power consumption...</p>
									</div>
								</div>
							</div>
							<div className=' col-md-6 col-lg-4 g-0'>
								<div className='text-box planetimg'>
									<img src={"assets/images/provide/PC_Vector.png"} alt='planet img' className='planet-img' />
								</div>
							</div>
							<div className='col-md-6 col-lg-4 g-0'>
								<div className='blog-item'>
									<div className='text-box'>
										<a href='02_blog.html' className='title-blog'>
											<h5>Social Initiatives </h5>
										</a>
										<p className='sec-explain-darkgray'>
											As a business, it is also crucial to reflect our commitment to societal progress and by <strong>Qualitech Charitable Trust</strong>, we demonstrate our dedication to societal progress, positive change, and fostering inclusivity as a business, particularly by empowering and uplifting underprivileged communities.
										</p>
									</div>
								</div>
							</div>
						</div>
						{/*<div className='row justify-content-md-center'>
							<div className='col-md-6 col-lg-4'>
								<div className='blog-item'>
									<div className='img-box'>
										<a href='02_blog.html' className='open-post'>
											<img className='img-fluid' src='assets/images/blog/02_blog.jpg' alt='02 Blog' />
										</a>
									</div>
									<div className='text-box'>
										<a href='02_blog.html' className='title-blog'>
											<h5>Green Initiative</h5>
										</a>
										<p>In today’s day and age with increasing global concerns, it is pivotal to operate with mindfulness and sustainability...</p>
									</div>
								</div>
							</div>
							<div className='col-md-6 col-lg-4'>
								<div className='blog-item'>
									<div className='img-box'>
										<a href='02_blog.html' className='open-post'>
											<img className='img-fluid' src='assets/images/blog/03_blog.jpg' alt='03 Blog' />
										</a>
									</div>
									<div className='text-box'>
										<a href='02_blog.html' className='title-blog'>
											<h5>SOCIAL Initiatives </h5>
										</a>
										<p>Qualitech Charitable Trust. Helping in organizing free workshops for engineers to promote technology and manufacturing industry locally ...</p>
									</div>
								</div>
							</div>
	</div> */}
						<div className='provide-core-list-action'>
							<Link to='/accolades-and-initiatives' className='btn-1 btn-3 link rounded-pill'>
								See more
							</Link>
						</div>
					</div>

					<div className='quote-box' id='quote-box'>
						<div className='row'>
							<div className='col-md-6'>
								<div className='sec-title'>
									<h3>Connect with us!</h3>
									<p className='sec-explain sec-explain-darkgray'>Fill out our "Enquire Now" form and let us assist you in finding the perfect metal pipe manufacturing and fabrication solutions tailored to your requirements.</p>
								</div>
								<div className='contact-info'>
									<h4>For additional information, please contact</h4>
									<div className='row'>
										<div className='col-lg-6'>
											<div className='contact-box'>
												<i className='ar-icons-email'></i>
												<div className='box'>
													<a className='mail sec-explain-darkgray' href='mailto:sales@qualitechgroup.in'>
														sales@qualitechgroup.in
													</a>
													<a className='mail sec-explain-darkgray' href='mailto:info@qualitechgroup.in'>
														info@qualitechgroup.in
													</a>
												</div>
											</div>
										</div>
										<div className='col-lg-6 contact-number'>
											<div className='contact-box'>
												<i className='ar-icons-call'></i>
												<div className='box'>
													<a className='phone sec-explain-darkgray' href='tel:+91 9414195835'>
														+91 9414195835
													</a>
													<a className='phone sec-explain-darkgray' href='tel:+91 8302114915'>
														+91 8302114915
													</a>
												</div>
											</div>
										</div>

										<div className='col-lg-6'>
											<div className='contact-box last'>
												<i className='ar-icons-location'></i>
												<div className='box'>
													<p className='sec-explain-darkgray'>Address,</p>
													<p className='sec-explain-darkgray'>C-11/E, M.I.A., Phase 1, Basni, Jodhpur, Rajasthan - 342005</p>
												</div>
											</div>
										</div>

										<div className='col-lg-6'>
											<div className='contact-box last'>
												<i className='ar-icons-location'></i>
												<div className='box'>
													<p className='sec-explain-darkgray'>Khasra No 48, 73, 82, -, Village Gaduda Tehsil Phagi,</p>
													<p className='sec-explain-darkgray'>Phagi, Jaipur, Rajasthan, 303005.</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<ContactUs />
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Provides;
