import ContactUs from "./home/ContactUs";

export const Contact = () => {
	return (
		<>
			<section className='breadcrumb-header style-2' id='page' style={{ background: "url(assets/images/header/08_header.jpg) no-repeat center / cover" }}>
				<div className='overlay'></div>
				<div className='container'>
					<div className='row'>
						<div className='col-md-8'>
							<div className='banner'>
								<h1>Contact US</h1>
								<ul>
									<li>
										<a href='index.html'>Home</a>
									</li>
									<li>
										<i className='fas fa-angle-right'></i>
									</li>
									<li>Contact US</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>

			<div className='contact-us py-100'>
				<div className='container'>
					<div className='contact-info-content'>
						<div className='row'>
							<div className='col-md-6 col-lg-3'>
								<div className='contact-box'>
									<i className='ar-icons-call'></i>
									<div className='box'>
										<a className='phone' href='tel:+91 9414195835'>
											+91 9414195835
										</a>
										<a className='phone' href='tel:+91 8302114915'>
											+91 8302114915
										</a>
									</div>
								</div>
							</div>
							<div className='col-md-6 col-lg-3'>
								<div className='contact-box'>
									<i className='ar-icons-email'></i>
									<div className='box'>
										<a className='mail' href='mailto:sales@qualitechgroup.in'>
											sales@qualitechgroup.in
										</a>
										<a className='mail' href='mailto:info@qualitechgroup.in'>
											info@qualitechgroup.in
										</a>
									</div>
								</div>
							</div>
							<div className='col-md-6 col-lg-3'>
								<div className='contact-box'>
									<i className='ar-icons-location'></i>
									<div className='box'>
										<p>C-11/E, M.I.A., Phase 1,</p>
										<p> Basni, Jodhpur, Rajasthan - 342005.</p>
									</div>
								</div>
							</div>
							<div className='col-md-6 col-lg-3'>
								<div className='contact-box'>
									<i className='ar-icons-location'></i>
									<div className='box'>
										<p>Khasra No 48, 73, 82, -, Village Gaduda Tehsil Phagi,</p>
										<p>Phagi, Jaipur, Rajasthan, 303005.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-lg-6'>
							<div className='map-box'>
								<iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3579.1809658362536!2d73.00912657454148!3d26.223308889378785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39418bec05ff639d%3A0xfedbbf1531ff2050!2sQualitech%20Metal%20Industries!5e0!3m2!1sen!2sus!4v1688924924740!5m2!1sen!2sus' width='600' height='450' style={{ border: "0" }} allowfullscreen='' loading='lazy' referrerpolicy='no-referrer-when-downgrade'></iframe>
							</div>
						</div>
						<div className='col-lg-6'>
							<div className='quote-box contactUs'>
								<ContactUs />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
