import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ProductsSlider = ({ imageSet }) => {
	const images = imageSet.map((image, index) => <img src={`assets/images/projects/${image}`} alt={index} />);
	return (
		<>
			<Carousel autoPlay verticalSwipe>
				{images}
			</Carousel>
		</>
	);
};

export default ProductsSlider;
