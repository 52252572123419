import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Testimonial = () => {
	return (
		<>
			<section className='testimonial py-0-0-70-0'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-10 offset-md-1'>
							<div className='testimonial-carousel'>
								<OwlCarousel
									animateOut='fadeOut'
									animateIn='fadeIn'
									loop
									nav
									autoplay={true}
									center
									autoplayTimeout={4000}
									smartSpeed={1000}
									autoplayHoverPause
									URLhashListener
									startPosition='URLHash'
									mouseDrag
									touchDrag
									navText={['<i className="ar-icons-left-chevron"></i>', '<i className="ar-icons-right-chevron"></i>']}
									responsive={{
										0: {
											items: 1,
										},
										768: {
											items: 1,
										},
										991: {
											items: 1,
										},
									}}>
									<div className='item-box'>
										<div className='text-box italic'>
											<i class='fa fa-quote-left'></i>
											Nagarjuna Construction Company (NCC) <br /> This is to recognize QMI’s unparalleled efforts and services towards successfully completing the Bilaspur water supply project, Phase II.
											<i class='fa fa-quote-right'></i>
										</div>
										<div className='item-name text-center'>
											<i className='ar-icons-right-quote'></i>
											<h5>Ajmer of PHED, Government of Rajasthan</h5>
											{/* <span>NiftyTheme Agency</span> */}
										</div>
									</div>
									<div className='item-box'>
										<div className='text-box italic'>
											<i class='fa fa-quote-left'></i>We would like to acknowledge QMI’s significant contribution by providing MS Pipes and Flanges for the completion of Bilaspur - Jaipur water supply.
											<i class='fa fa-quote-right'></i>
										</div>
										<div className='item-name text-center'>
											<i className='ar-icons-right-quote'></i>
											<h5>Kirloskar Brothers Limited</h5>
											{/* <span>AR-Coder Agency</span> */}
										</div>
									</div>
									<div className='item-box'>
										<div className='text-box italic'>
											<i class='fa fa-quote-left'></i>This is to certify QMI’s successful commission in providing SS Expansion Bellows at Barmer Lift Water Supply Project
											<i class='fa fa-quote-right'></i>
										</div>
										<div className='item-name text-center'>
											<i className='ar-icons-right-quote'></i>
											<h5>L&T Construction</h5>
											{/* <span>Founder &amp; CEO</span> */}
										</div>
									</div>
									<div className='item-box'>
										<div className='text-box italic'>
											<i class='fa fa-quote-left'></i>We certify QMI’s support towards the completion of various projects with on-time delivery and premium quality standards throughout.
											<i class='fa fa-quote-right'></i>
										</div>
										<div className='item-name text-center'>
											<i className='ar-icons-right-quote'></i>
											<h5>MEIL (Megha Engineering and Industries Limited)</h5>
											{/* <span>Founder &amp; CEO</span> */}
										</div>
									</div>
									<div className='item-box'>
										<div className='text-box italic'>
											<i class='fa fa-quote-left'></i>This is to certify that QMI has provided various pipes, dismantling and expansion joints and flanges which perform brilliantly till date for various pumping stations in Phalodi.
											<i class='fa fa-quote-right'></i>
										</div>
										<div className='item-name text-center'>
											<i className='ar-icons-right-quote'></i>
											<h5>Office of Executive Engineering (PHED RGLC)</h5>
											{/* <span>Founder &amp; CEO</span> */}
										</div>
									</div>
								</OwlCarousel>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Testimonial;
