const products = [
	{
		id: "p1",
		title: "MS (Mild Steel) Dismantling Joints",
		name: "MS Dismantling joints",
		info: "MS (Mild Steel) Dismantling Joints are double flanged fittings that accommodate up to 100mm (4”) longitudinal adjustment and can be locked at the required length with the tie bars supplied. Not only does this system allow for fast, easy maintenance of valves, pumps or meters, it simplifies future pipe work modifications and reduces downtime when changes need to be made. The installation is also straightforward using just a spanner and torque wrench to tighten the high tensile steel or stainless steel tie bars. With fewer tie bars than flange holes and the tie bars acting as flange jointing bolts the process is speeded up but still offers a secure, rigid, fully endload resistant system with a pressure rating equal to that of the flange. A comprehensive range is available from DN40 to DN2400 with virtually any flange drilling or pressure rating supplied, although larger sizes and custom made Dismantling Joints can be designed and built on request.",
		sku: "001",
		category: "Steel",
		images: ["MS-Dismantling-joints-01.jpg", "MS-Dismantling-joints-02.jpg", "MS-Dismantling-joints-03.jpg", "MS-Dismantling-joints-04.jpg"],
	},
	{
		id: "p2",
		name: "MS Pipes",
		title: "",
		info: "",
		sku: "",
		category: "",
		images: ["MS-Pipes-01.jpg", "MS-Pipes-02.jpg", "MS-Pipes-03.jpg", "MS-Pipes-04.jpg"],
	},
	{
		id: "p3",
		name: "DI Dismantling Joints",
		title: "",
		info: "",
		sku: "",
		category: "",
		images: ["DI-Dismantling-Joints-01.jpg", "DI-Dismantling-Joints-02.jpg", "DI-Dismantling-Joints-03.jpg"],
	},
	{
		id: "p4",
		title: "MS (Mild Steel) Flanges",
		name: "MS Flanges",
		info: "Flanges are used to connect pipes with each other, to valves, to fittings, and pumps and other equipment to form a piping system. It also provides easy access for cleaning, inspection or modification. Flanges are usually welded or screwed. Flanged joints are made by bolting together two flanges with a gasket between them to provide a sea. A cover plate can be connected to create a 'blind flange'. Flanges are joined by bolting, and sealing is often completed with the use of gaskets or other methods.  A comprehensive range is available from DN40 to DN2400  with virtually any flange drilling or pressure rating supplied, although larger sizes and custom made flanges can be designed and built on request.",
		sku: "002",
		category: "Steel",
		images: ["MS-Flanges-01.jpg", "MS-Flanges-02.jpg", "MS-Flanges-03.jpg", "MS-Flanges-04.jpg"],
	},
	{
		id: "p5",
		title: "MS (Mild Steel) Flange Adapters",
		name: "MS Flanges Adapters",
		info: "MS (Mild Steel) Flange Adapters are used to connect pipes or other equipment with flanged connections. They are made of mild steel material and have a flange on one end and a plain end on the other, allowing for easy connection to pipes or other equipment with flanged connections. MS Flange Adapters can be used in a variety of applications, including in plumbing systems, piping systems, HVAC systems, and industrial applications. They come in a variety of sizes and can be manufactured to meet specific requirements for pressure and temperature ratings.",
		sku: "002",
		category: "Steel",
		images: ["MS-Flange-Adapters-01.jpg", "MS-Flange-Adapters-02.jpg", "MS-Flange-Adapters-03.jpg"],
	},
	{
		id: "p6",
		name: "DI Flanges Adapters",
		title: "",
		info: "",
		sku: "",
		category: "",
		images: ["DI-Flange-Adapters-01.jpg", "DI-Flange-Adapters-02.jpg", "DI-Flange-Adapters-03.jpg", "DI-Flange-Adapters-04.jpg"],
	},
	{
		id: "p7",
		title: "MS (Mild Steel) Expansion Joints",
		name: "MS Expansion Joints",
		info: "MS (Mild Steel) Expansion Joints are flexible connectors used to accommodate movement and thermal expansion in piping systems. They are made of mild steel and are designed to absorb movement and reduce stress on connected pipes and equipment. MS Expansion Joints are commonly used in piping systems that carry hot or cold fluids, such as steam, water, and gasses. They come in a variety of lengths and sizes to accommodate different piping systems and can be manufactured to meet specific pressure and temperature requirements. MS Expansion Joints are also available in a variety of configurations, including single- and multi-ply, and they can be fitted with flanges, nipples, or other fittings for easy installation.",
		sku: "002",
		category: "Steel",
		images: ["MS-Expansion-Joints-01.jpg", "MS-Expansion-Joints-02.jpg", "MS-Expansion-Joints-03.jpg"],
	},
	{
		id: "p8",
		name: "SS Expansion Bellows",
		title: "",
		info: "",
		sku: "",
		category: "",
		images: ["SS-Expansion-Bellows-01.jpg", "SS-Expansion-Bellows-02.jpg", "SS-Expansion-Bellows-03.jpg"],
	},
	{
		id: "p9",
		name: "DI Flanges Pipes",
		title: "",
		info: "",
		sku: "",
		category: "",
		images: ["DI-Flanges-Pipes-01.jpg", "DI-Flanges-Pipes-02.jpg", "DI-Flanges-Pipes-03.jpg"],
	},
	{
		id: "p10",
		name: "DI Puddle Pipes",
		title: "",
		info: "",
		sku: "",
		category: "",
		images: ["DI-Puddle-Pipes-01.jpg", "DI-Puddle-Pipes-02.jpg", "DI-Puddle-Pipes-03.jpg"],
	},
	{
		id: "p11",
		name: "DI Pipe Fittings",
		title: "",
		info: "D.I. (Ductile Iron) Fittings are pipe fittings used in plumbing, piping, and drainage systems. They are made of ductile iron, a type of cast iron with high tensile strength and ductility. D.I. fittings are commonly used in water and sewage systems, as well as in other applications that require durable and strong pipe connections.D.I. fittings come in various shapes and sizes, including elbows, tees, crosses, reducers, and flange adaptors, to accommodate different piping configurations. They can be threaded, socket-welded, or flanged for easy installation and secure connections. D.I. fittings can also be coated or lined with materials to enhance their resistance to corrosion and wear.",
		sku: "",
		category: "",
		images: ["DI-Pipe-Fittings-01.jpg", "DI-Pipe-Fittings-02.jpg", "DI-Pipe-Fittings-03.jpg", "DI-Pipe-Fittings-04.jpg"],
	},
	{
		id: "p12",
		name: "MS Pipe Fittings",
		title: "",
		info: "M.S.  (Mid Steel) Pipe Fittings are fittings used in plumbing, piping and drainage systems. They are made of cast iron, a type of iron with high tensile strength and durability. Cast iron fittings are commonly used in water and sewage systems, as well as in other applications that require strong and long-lasting pipe connections. M.S.  pipe fittings come in various shapes and sizes, including elbows, tees, crosses, reducers, and flange adaptors, to accommodate different piping configurations. They can be threaded, socket-welded, or flanged for easy installation and secure connections. Cast iron fittings are also available with coatings or linings to enhance their resistance to corrosion and wear.",
		sku: "",
		category: "",
		images: ["MS-Pipe-Fittings-01.jpg", "MS-Pipe-Fittings-02.jpg", "MS-Pipe-Fittings-03.jpg", "MS-Pipe-Fittings-04.jpg"],
	},
	{
		id: "p13",
		name: "Cast Iron Pipe Fittings",
		title: "",
		info: "",
		sku: "",
		category: "",
		images: ["Cast-Iron-Pipe-Fittings-01.jpg", "Cast-Iron-Pipe-Fittings-02.jpg", "Cast-Iron-Pipe-Fittings-03.jpg"],
	},
	{
		id: "p14",
		name: "Leak Repair Clamps",
		title: "",
		info: "M.S.  (Mid Steel) Socket Leak Repair Clamps are devices used to repair leaks in pipes. They are made of cast iron, a type of iron with high tensile strength and durability. M.S.  socket leak repair clamps are used to repair leaks in pipes without the need to shut off the water supply or drain the pipe. The clamp consists of a cast iron band with a rubber gasket or a repair sleeve that is placed over the leaky section of the pipe. The band is tightened with bolts, compressing the gasket or repair sleeve against the pipe, effectively sealing the leak. M.S. Socket leak repair clamps come in various sizes to accommodate different pipe diameters and can be manufactured to meet specific pressure and temperature requirements. M.S. Socket leak repair clamps are easy to install and provide a quick and effective solution for leaky pipes, without the need for more extensive and time-consuming repairs. They are a cost-effective alternative to replacing the entire pipe and can help extend the life of pipes, making them an important component in many plumbing, piping, and drainage systems.",
		sku: "",
		category: "",
		images: ["Leak-Repair-Clamps-01.jpg", "Leak-Repair-Clamps-02.jpg", "Leak-Repair-Clamps-03.jpg", "Leak-Repair-Clamps-04.jpg"],
	},
	{
		id: "p15",
		name: "D.I./M.S. D/F Pipes",
		title: "D.I./M.S. D/F Pipes",
		info: "D.I./M.S. D/F Pipes are made of ductile iron or Mild Steel and have double flanges on both ends for easy connection to other pipes and fittings. They are commonly used in water, sewage and other drainage systems for the conveyance of fluids.",
		sku: "",
		category: "",
		images: ["MS-Pipes-01.jpg", "MS-Pipes-02.jpg", "MS-Pipes-03.jpg", "MS-Pipes-04.jpg"],
	},
	{
		id: "p16",
		name: "SAW Pipes",
		title: "SAW Pipes",
		info: 'The spiral-welded pipe is formed by twisting strips of metal into a spiral shape, similar to a barbers pole,	then welding where the edges join one another to form a seam. ',
		info1: 'This type of pipe is restricted to piping systems using low pressures due to its thin walls.',
		info2: 'L SAW pipes are made from a steel plate with only one weld seam joining the two edges of the rolled plate.',
		info3: 'H SAW pipes are manufactured by helically rolling the steel coil. The features of H SAW pipes include • The manufacturing process offers the advantage of making large diameter pipes in a cost effective manner. • The manufacturing process offers the advantage of making high thickness pipes in a cost effective manner. • With use of improved technology, welding quality has improved and these pipes can withstand higher pressures. • However, inspection requirement are more stringent.',
		table_image: "product-spec.png",
		sku: "",
		category: "",
		images: ["01.jpg"],
	}
];

export default products;
